// Main colors from 🐉 Dragon
// https://www.figma.com/file/NMTPo41tvoQOoojdjLxWG8/%F0%9F%90%89-Dragon-%3A%3A-Vaki's-design-system?node-id=2660%3A2174
@use './../themes/vaki/colors' as vakiColors;
$PRIMARY: map-get(vakiColors.$vaki-primary-palette, 500);
$PRIMARY_LIGHT: map-get(vakiColors.$vaki-primary-palette, 200);
$PRIMARY_VARIANT: map-get(vakiColors.$vaki-primary-palette, 900);
$SECONDARY: map-get(vakiColors.$vaki-accent-palette, 500);
$SECONDARY_VARIANT: map-get(vakiColors.$vaki-accent-palette, 200);
$BACKGROUND_DARK: map-get(vakiColors.$vaki-dark-palette, 500);
$DARK_DARKER: map-get(vakiColors.$vaki-dark-palette, 700);
$DARK_LIGHTER: map-get(vakiColors.$vaki-dark-palette, 400);
$SECONDARY_TEXT: map-get(vakiColors.$vaki-dark-palette, 300);
$DARK_VARIANT: map-get(vakiColors.$vaki-dark-palette, 200);
$LIGHT_VARIANT: map-get(vakiColors.$vaki-primary-palette, 150);
$DISABLED: map-get(vakiColors.$vaki-dark-palette, 100);
$BACKGROUND_LIGHT: map-get(vakiColors.$vaki-dark-palette, 50);
$WARN: map-get(vakiColors.$vaki-warn-palette, default);

@mixin colors-theme($theme) {
  $primary: map-get(map-get($theme, primary), 500);
  $accent: map-get(map-get($theme, accent), 500);
  $warn: map-get(map-get($theme, warn), default);
  $background: map-get(map-get($theme, primary), darker);
  $background-lighter: map-get(map-get($theme, primary), lighter);
  $background-gray: map-get(map-get($theme, accent), lighter);
  $primary-contrast: map-get(map-get($theme, primary), '50-contrast');
  $primary-lighter: map-get(map-get($theme, primary), 300);
  $background-gray-ligther: map-get(map-get($theme, accent), 50);
  .bg-light {
    background-color: white;
  }
  .bg-primary {
    background-color: $primary;
  }
  .bg-primary-dark {
    background-color: $background;
  }
  .bg-primary-light {
    background-color: $background-lighter;
  }
  .bg-gray {
    background-color: $background-gray;
  }
  .bg-gray-light {
    background-color: $background-gray-ligther;
  }
  .bg-accent {
    background-color: $primary-contrast;
  }
  .bg-dark {
    background-color: $accent;
  }
  .bg-container {
    background-image: url('./../../../../../util/front-end/src/assets/images/misc/hero-home-vaki.png');
    color: white;
    background-size: 100% 100%;
    background-repeat: no-repeat;
    background-position: left top;
  }

  .bg-default {
    background-color: $background;
  }
  .text-primary {
    color: $primary;
  }
  .text-accent {
    color: $primary-contrast; // need to refactor components to text-dark
    // color: $accent; // un comment this after refactor
  }
  .text-dark {
    color: $primary-contrast; // need to refactor components to text-dark
  }
  .text-white {
    color: white !important;
  }
  .text-secondary {
    color: $accent;
  }
  .text-warn {
    color: $warn;
  }
  .text-gray {
    color: $DARK_LIGHTER;
  }

  .border-primary,
  %border-primary {
    border-color: $primary;
  }
  .border-dark {
    border-color: $primary-contrast;
  }
  .border-primary-light {
    border-color: $primary-lighter;
  }
  .border-disabled {
    border-color: $DISABLED;
  }
}

$vaki-status-colors: (
  'light': #c4c4c4,
  'purple': #7660ff,
  'yellow': #ffdb43,
  'red': #eb5757,
  'green': #27ae60,
  'light-blue': #56ccf2,
  'blue': #2f80ed,
  'orange': #f2994a,
  'light-green': #6fcf97,
  'light-purple': #7f22fd,
  'dark': #21293a
);
